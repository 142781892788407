<template>
  <div>
    <transition name="page">
      <component
        v-if="businessType"
        :is="componentInsuranceId"
        :order="order"
        @getClaim="callOrderGet"
      />
    </transition>
  </div>
</template>

<script>
import common from '@/mixins/common';
import LogService from '@/services/LogService';

export default {
  mixins: [common],
  data() {
    return {
      apiOrderGet: '/api/user/order/',
      order: {},
      mapComponents: {
        vacation: 'insuranceIdVacation',
        default: 'insuranceIdCommon',
      },
    };
  },
  components: {
    insuranceIdVacation: () => import(/* webpackChunkName: "insuranceIdVacation" */ '@/components/insuranceId/InsuranceIdVacation.vue'),
    insuranceIdCommon: () => import(/* webpackChunkName: "insuranceIdCommon" */ '@/components/insuranceId/InsuranceIdCommon.vue'),
  },
  computed: {
    componentInsuranceId() {
      const individualPages = Object.keys(this.mapComponents).filter((p) => p !== 'default');
      const businessType = individualPages.includes(this.businessType) ? this.businessType : 'default';
      const component = this.mapComponents[businessType];
      return component;
    },
    businessType() {
      return this.order.business?.type;
    },
  },
  methods: {
    /**
     * call order get
     */
    async callOrderGet() {
      if (!this.$route.params.id) {
        Promise.reject(new Error('no id'));
      }

      let res;
      try {
        res = await this.callApi(this.apiOrderGet + this.$route.params.id);
        this.order = res?.data?.order || {};
      } catch (e) {
        if (e.response?.status === 404) {
          this.$router.push({ name: '404', params: { ...this.$route.params } });
        } else this.$router.replace({ name: 'login', params: { ...this.$route.params }, query: { redirect: 'insurance' } });
      }
      LogService.log('res', res);
    },
  },
  mounted() {
    this.callOrderGet();
  },
};
</script>
<style lang="scss">
.wet-aspect-16-9 {
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  &>*:first-child {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.wet-aspect-half {
  padding-top: calc(1 / 2 * 100%);
  position: relative;
  &>*:first-child {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.wet-map-label {
  position: absolute;
  top: 0;
  left: 1rem;
  font-weight: bold;
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>
